import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles/Home.module.css";
import Layout from "./components/Layout";
import NewsItem from "./components/NewsItem";
import { news } from "./data/hub"; // Ensure the path to `hub.js` is correct


export default function Home() {
  // Get the latest 3 news items
  const latestNews = news.slice(0, 3);

  return (
 
      <div className={styles.container}>
        <Layout
        title= "D24 Media"
        description= "D24 Media the Innovator in Web 3.0 Blockchain, mobile application Producer based in Sydney, Australia"
        keywords= "D24 Media, Decentralised websites, Wordpress, Wix, React, Nextjs, Web3.0, blockchain, web producer"
       
        >
          <main className={styles.main}>
            <h1>
              <code className={styles.code}>D24 Digital Media</code>
            </h1>
            <h2>
              <code className={styles.code}>
                Decentralised Web 3.0 Producer. Specialise in managing website
                production, SEO, Digital Marketing, and Blockchain Development.
                More about{" "}
                <Link to="/about" title="D24 Media">
                  D24 Media
                </Link>
              </code>
            </h2>

            <div className={styles.grid}>
              {/* Card Links */}
              {[
                {
                  to: "/about",
                  imgSrc: "/dweb-icon.svg",
                  imgAlt: "D24 Digital Media",
                  title: "Decentralised website",
                  heading: "DWeb",
                  text: "Web3: the future of Web Technology",
                },
                {
                  to: "/portfolio",
                  imgSrc: "/develop-icon.svg",
                  imgAlt: "Website Design",
                  title: "Website Design",
                  heading: "Develop",
                  text: "The Next Generation of secure websites!",
                },
                {
                  to: "/about",
                  imgSrc: "/deploy-icon.svg",
                  imgAlt: "Website Producer",
                  title: "Website Producer",
                  heading: "Deploy",
                  text: "Advanced technology for the Cyberworld.",
                },
                {
                  to: "/contact",
                  imgSrc: "/discover-icon.svg",
                  imgAlt: "SEO",
                  title: "SEO",
                  heading: "Discover",
                  text: "100% Search Engine Friendly Website.",
                },
              ].map((item, idx) => (
                <Link
                  to={item.to}
                  title={item.title}
                  className={styles.card}
                  key={idx}
                >
                  <img
                    src={item.imgSrc}
                    alt={item.imgAlt}
                    width={160}
                    height={160}
                  />
                  <h2>{item.heading}</h2>
                  <p>{item.text}</p>
                </Link>
              ))}
            </div>
          </main>

          <div className={styles.grid}>
            <h2>
              <Link
                to="/hub"
                className={styles.back}
                title="Learn Digital Media Web3, Cyber Security"
              >
                <code className={styles.code}>The Hub</code>
              </Link>
            </h2>
          </div>

          {/* News Section */}
          <section>
            <h2 className={styles.newsHeader}>Latest News</h2>
            {latestNews.length === 0 ? (
              <h3>No News</h3>
            ) : (
              latestNews.map((item) => <NewsItem key={item.id} news={item} />)
            )}
          </section>
        </Layout>
      </div>
  );
}

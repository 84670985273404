import React from "react";
import { Link } from "react-router-dom"; // For internal links
import styles from "./styles/Home.module.css"; // Ensure the path matches your project structure
import Layout from "./components/Layout"; // Ensure Layout component is set up correctly

const Contact = () => {
  return (
    <div className={styles.container}>
      <Layout
        title="Contact D24 Media"
        description="Creative & Innovative Leading Edge Digital Media Producer and Cyber Security Specialist. D24 Media is located in Sydney Australia"
        keywords="learn blockchain, learn web3, learn web2, learn web design, learn web development"
      >
        <main className={styles.main}>
          {/* Header Section */}
          <h1>
            <code className={styles.code}>Getting in touch with D24 Media</code>
          </h1>
          <h2>
            <code className={styles.code}>Your Cyber-savvy Web Producer</code>
          </h2>

          {/* Contact Links */}
          <div className={styles.grid}>
            {[
              {
                href: "https://github.com/D24media",
                imgSrc: "/github.svg",
                imgAlt: "D24 Media Github",
                title: "Github",
              },
              {
                href: "https://www.linkedin.com/company/d24media/",
                imgSrc: "/linkedin-icon.svg",
                imgAlt: "D24 Media Linkedin",
                title: "Linkedin",
              },
              {
                href: "https://www.instagram.com/d24digitalmedia/",
                imgSrc: "/instagram-icon.svg",
                imgAlt: "D24 Digital Media Instagram",
                title: "Instagram",
              },
              {
                href: "https://www.facebook.com/D24me/",
                imgSrc: "/facebook-messager-icon.svg",
                imgAlt: "D24 Media Facebook Fanpage",
                title: "Facebook",
              },
            ].map((item, idx) => (
              <a
                key={idx}
                href={item.href}
                className={styles.contactbox}
                target="_blank"
                rel="noopener"
              >
                <img
                  src={item.imgSrc}
                  alt={item.imgAlt}
                  width={120}
                  height={120}
                />
                <h3>{item.title}</h3>
              </a>
            ))}
          </div>
        </main>
      </Layout>
    </div>
  );
};

export default Contact;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home"; // Home Page
import NewsDetails from "./hub/[slug]"; // Dynamic News Details Page
import Hub from "./hub/index"; // News Listing Page (Hub)
import About from "./about"; // About Page
import Contact from "./contact"; // Contact Page
import Portfolio from "./portfolio"; // Portfolio Page

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/hub" element={<Hub />} />
        <Route path="/hub/:slug" element={<NewsDetails />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/NewsItem.module.css"; // Ensure the path to your CSS file is correct
import { news } from "../data/hub"; // Ensure the path to hub.js is correct
import Layout from "../components/Layout"; // Ensure Layout component is properly set up

const Hub = () => {
  return (
    <Layout
      title="D24 Media Hub"
      description="Explore the latest insights and updates from D24 Media"
      keywords="D24 Media, News, Updates, Hub"
    >
      <div className={styles.newsContainer}>
        <h1 className={styles.title}>
          <code className={styles.code}>D24 Digital Media Learning Hub</code>
        </h1>
        <div className={styles.newsGrid}>
          {news.length === 0 ? (
            <h3>No News Available</h3>
          ) : (
            news.map((item) => (
              <div key={item.id} className={styles.news}>
                <div className={styles.newitems}>
                  <Link to={`/hub/${item.slug}`} title={item.name}>
                    <img
                      src={item.image}
                      alt={item.name}
                      width="260"
                      height="100"
                      style={{ objectFit: "cover", maxWidth: "100%" }}
                    />
                  </Link>
                </div>
                <div className={styles.info}>
                  <h3>
                    <Link to={`/hub/${item.slug}`} title={item.name}>
                      {item.name}
                    </Link>
                  </h3>
                  <p>{item.summary}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Hub;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Header.module.css";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className={styles.header}>
      {/* Logo Section */}
      <div className={styles.logo}>
        <Link to="/" title="Home">
          <img
            itemProp="image"
            src="/D24-Media.svg"
            alt="D24 Media"
            width={260}
            height={260}
            loading="eager"
          />
        </Link>
      </div>

      {/* Navigation Menu */}
      <nav className={showMenu ? styles.show : styles.hide}>
        <ul className={styles.menu}>
          {/* Use hardcoded paths and simple links */}
          <li>
            <Link to="/" title="Home">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" title="About D24 Media">
              About
            </Link>
          </li>
          <li>
            <Link to="/portfolio" title="View Portfolio">
              Portfolio
            </Link>
          </li>
          <li>
            <Link to="/hub" title="Visit the Hub">
              Hub
            </Link>
          </li>
          <li>
            <Link to="/contact" title="Contact Us">
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      {/* Hamburger Button */}
      <button
        className={styles.hamb}
        onClick={toggleMenu}
        id="d24menu"
        title="Toggle Menu"
      >
        {showMenu ? (
          <span className={styles.close}>Close</span>
        ) : (
          <span className={styles.hambline}>Menu</span>
        )}
      </button>
    </header>
  );
};

export default Header;

import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for internal navigation
import styles from "./styles/Home.module.css"; // Ensure the path is correct
import Layout from "./components/Layout"; // Ensure Layout is properly set up

const Portfolio = () => {
  return (
    <div className={styles.container}>
      <Layout
        canonical="https://d24.me/portfolio"
        title="D24 Media Portfolio"
        description="D24 Creative & Innovative Leading Edge Digital Media Producer. D24 Media is located in China Hong Kong Sydney Australia"
        keywords="D24, learn web3, learn web2, learn web design, learn web development"
      >
        <main className={styles.main}>
          <h1>
            <code className={styles.code}>Portfolio</code>
          </h1>
          <h2>
            <code className={styles.code}>
              More time in Quality of outcome and less time in getting job done.
            </code>
          </h2>
          <p>
            D24 Media we spend the most effort from start to the end of all the
            projects. We can find a perfect solution to help you expose your
            business on the internet. Take a look at my past work below from
            Cyber Security, Brand designing to strategic digital marketing,
            website design and development, social media engagement, and offline
            marketing.
          </p>

          <div className={styles.grid}>
            {/* Portfolio Links */}
            {[
              {
                href: "https://cyberlabau.com",
                imgSrc: "/images/cyberlab.png",
                imgAlt: "Cyberlab Australia",
                title: "CyberLab Australia",
                heading: "Cyberlab",
                description: "Safeguard your digital assets",
              },
              {
                href: "https://cr8tiv.com",
                imgSrc: "/images/cr8tiv.png",
                imgAlt: "Cr8tiv",
                title: "Creative",
                heading: "Cr8tiv",
                description: "Digital Experience Design",
              },
              {
                href: "https://cars.autosalonstudio.com",
                imgSrc: "/images/car-detailing-sydney.png",
                imgAlt: "Car detailing Sydney",
                title: "Car Detailing Sydney",
                heading: "Cars",
                description: "SEO Friendly Website for local businesses",
              },
              {
                href: "https://xformmedia.com",
                imgSrc: "/images/xform.png",
                imgAlt: "Xform Media",
                title: "Xform",
                heading: "Xform",
                description: "Decentralised Hosting Solution Provider",
              },
              {
                href: "https://guitarscales.live",
                imgSrc: "/images/guitar-scales.png",
                imgAlt: "Guitar Scales Online",
                title: "Learn Guitar Scales",
                heading: "Guitar Scales",
                description: "Learn and master your guitar scales",
              },
              {
                href: "https://www.pyfsc.com",
                imgSrc: "/images/feng-shui-master.png",
                imgAlt: "Feng Shui Master",
                title: "Feng Shui Master",
                heading: "PYFSC",
                description: "Multi-language Blog Website",
              },
              {
                href: "https://mychinesehoroscope.com",
                imgSrc: "/images/mychinesehoroscope.png",
                imgAlt: "mychinesehoroscope",
                title: "mychinesehoroscope",
                heading: "My Chinese Horoscope",
                description: "Ancient Chinese Horoscope Zodiac Finder",
              },
              {
                href: "https://seoptimizer.net",
                imgSrc: "/images/seoptimizer.png",
                imgAlt: "SEOptimizer",
                title: "SEOptimizer",
                heading: "SEOptimizer",
                description: "Improve your SEO with these tools",
              },
            ].map((item, idx) => (
              <a
                href={item.href}
                className={styles.card}
                title={item.title}
                target="_blank"
                rel="noopener noreferrer"
                key={idx}
              >
                <img
                  src={item.imgSrc}
                  alt={item.imgAlt}
                  width={120}
                  height={120}
                />
                <h2>{item.heading}</h2>
                <p>{item.description}</p>
              </a>
            ))}
          </div>
        </main>
      </Layout>
    </div>
  );
};

export default Portfolio;

import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for navigation
import styles from "./styles/Home.module.css"; // Ensure the path is correct
import Layout from "./components/Layout"; // Ensure Layout component is properly set up

const About = () => {
  return (
    <div className={styles.container}>
      <Layout
        canonical="https://d24.me/about"
        title="About D24 Media"
        description="Creative & Innovative Leading Edge Digital Media Producer and Cyber Security Specialist. D24 Media is located in Sydney, Australia"
        keywords="D24 Media, d24 digital media, d24, Cyber Security Solution, web design, web developer, full stack, digital marketing"
      >
        <main className={styles.main}>
          {/* Page Header */}
          <h1>
            <code className={styles.code}>D24 Media - Your Digital Experience Specialist</code>
          </h1>
          <h2>
            <code className={styles.code}>
              Web Production Excellence & Personalised Digital Journeys
            </code>
          </h2>
          <h3>
            <code className={styles.code}>
              Competitive edge in the ever-evolving digital space requires embracing inventiveness
            </code>
          </h3>

          {/* Introduction Section */}
          <p>
            Web Production Specialist with over 20 years of experience in web design and development
            located in Sydney, Australia, with exposure worldwide. <strong>D24 Media</strong>{" "}
            creativity and innovation is essential in our dynamic technological digital
            transformation age. With over a decade of experience in providing online web services,
            UI/UX, and development since 1996, we have seen the changes since the early days of
            computing and the evolution of the internet. We possess expertise that extends from
            engaging designs, web project management, SEO digital marketing, and Cyber Security
            Solutions. <strong>D24 Media</strong> has successfully partnered with numerous
            organisations worldwide.
          </p>

          {/* Expertise Section */}
          <h3>
            <code className={styles.code}>Tech Savvy Web Producer</code>
          </h3>
          <p>
            Web Production Producer expertise spans a broad spectrum, ranging from proficiency in
            digital media, UI and UX design, and development. In addition to my proficiency in
            secure programming and beyond, I bring this comprehensive skill set to the forefront of
            unique design and final development production. In addition to being well-versed in SEO,
            social media marketing, branding, strategic marketing, user interface designs, and user
            experience, my proficiency extends to Metaverse 3D and 2D animation, and video
            production. Whether improving website visibility in search engines or delivering
            end-to-end brand design and website development solutions for startups, my diverse
            skills cater to the dynamic needs of businesses across industries.
          </p>

          {/* Experience Section */}
          <h3>
            <code className={styles.code}>Professional Experience</code>
          </h3>
          <p>
            <i>D24 Digital Media</i> has extensive experience in web development and cloud
            services. Undertaking diverse professional projects, we recognise the imperative for a
            seamless integration of web services to another level. The digital realm demands not
            just aesthetic excellence but also impenetrable security. Staying ahead of the online
            world, we consistently update our skills to offer proactive solutions. This strategic
            evolution ensures that our clients not only experience visually appealing and
            user-friendly websites but also benefit from advanced cybersecurity measures in an
            ever-evolving digital landscape. We are qualified to address challenges and solutions
            for any organisation, delivering groundbreaking solutions, staying ahead of web
            technology, and contributing to advancements in securing the digital world.
          </p>

          {/* Skills List */}
          <ul>
            <li>Web 3.0</li>
            <li>ReactJS, NextJS</li>
            <li>Blockchain Development</li>
            <li>NFT Marketplace</li>
            <li>Digital Marketing</li>
            <li>Social Media Marketing</li>
            <li>Search Engine Optimisation</li>
            <li>Corporate Branding Identity</li>
            <li>User Interface Design</li>
            <li>2D Print Production</li>
            <li>Event Booth Designing</li>
            <li>Video Production</li>
            <li>3D Modeling and Animation</li>
            <li>Cloud/VPS Hosting Solution</li>
            <li>Decentralised Private Hosting Solution</li>
            <li>Domain Name Services</li>
            <li>Network Security</li>
          </ul>

          {/* Portfolio Link */}
          <p>
            Check out{" "}
            <Link to="/portfolio" title="D24 Media Portfolio">
              D24 Media Portfolio
            </Link>{" "}
            which showcases the quality of our work and extensive knowledge in this digital world.
          </p>
        </main>
      </Layout>
    </div>
  );
};

export default About;

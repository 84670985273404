import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/NewsItem.module.css";

const NewsItem = ({ news }) => {
  return (
    <div className={styles.news}>
      <div className={styles.newitems}>
        <Link to={`/hub/${news.slug}`} title={news.name}>
          <img
            src={news.image}
            alt={news.name}
            width="260"
            height="100"
            style={{ objectFit: "cover", maxWidth: "100%" }}
          />
        </Link>
      </div>
      <div className={styles.info}>
        <h3>
          <Link to={`/hub/${news.slug}`} title={news.name}>
            {news.name}
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default NewsItem;
